@use '../../styles' as *;

.speedboostvideospages {
  padding: -3rem -2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #0099d8;

  button {
    height: 3rem;
    font-size: 1.15rem !important;
    background-color: #0099d8;
    border-radius: 30px;
    margin-bottom: 2rem;
  }
  h1 {
    text-align: center;
    vertical-align: middle;
    line-height: normal;
    word-wrap: break-word;
    font-size: 3.5rem !important;
    font-weight: 500 !important;
    font-family: headline, sans-serif;
    margin-bottom: 1rem;
  }
  h2 {
    text-align: center;
    vertical-align: middle;
    line-height: normal;
    word-wrap: break-word;
    font-size: 1.75rem;
    font-weight: 100;
    font-family: subheadline, sans-serif;
    margin-bottom: 1.5rem;
  }
  p {
    text-align: center;
    vertical-align: middle;
    line-height: normal;
    word-wrap: break-word;
    max-width: 80%;
    font-size: 0.9rem;
    font-weight: 100;
    font-family: disclaimer, sans-serif;
  }
  video {
    width: 40%;
    margin-bottom: 2rem;
  }

  @media only screen and (max-width: 799px) {
    video {
      width: 90%;
    }
  }

  @media only screen and (min-width: 800px) and (max-width: 1200px) and (orientation: portrait) {
    video {
      width: 75%;
    }
  }

  @media only screen and (min-width: 800px) and (max-width: 1200px) and (orientation: landscape) {
    video {
      width: 85%;
    }
  }
}
