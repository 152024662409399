@use '../../styles' as *;

.intro {
    display: flex;
    justify-content: center;
    align-items: center;
    
    &__container-cell {
      display: flex;
      justify-content: right;
    }

    &__container {
      max-width: 463px !important;
    }
  
    &__image-cell {
      display: flex;
      justify-content: left;
      padding: 0 !important;
    }

    &__image-container {
      padding: 0 !important;
      max-width: 507px !important;
      max-height: 364px !important;
    }

    &__image {
      max-width: 100% !important;
      height: 100% !important;
    }
  
    &__title {
      font-weight: 700;
      font-style: italic;
      font-size: 40px;
      text-align: left;
      margin-bottom: 24px !important;
    }
  
    &__button {
      margin-top: 24px !important;
      padding: 8 !important;
      width: fit-content !important;
  
      .kite-btn__content {
        text-align: left;
        display: inline-block;
      }
    }

    @media only screen and (min-width: 480px) and (max-width: 1024px) {
      flex-direction: column;
  
      &__container-cell {
        justify-content: center;
      }

      &__container {
        margin-bottom: 32px;
        text-align: center;
      }

      &__image-cell {
        justify-content: center;
      }

      &__title {
        text-align: center;
      }
    }

    @media only screen and (min-width: 320px) and (max-width: 480px) {
      flex-direction: column;
  
      &__container-cell {
        justify-content: left;
      }

      &__container {
        margin-bottom: 32px;
        text-align: left;
      }

      &__image-cell {
        justify-content: left;
      }
    }
}
  