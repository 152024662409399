@import '../colors/variables';

// Options
// ---------------------------

// Quickly modify global styling by enabling or disabling optional features.

// $enable-rounded:            true !default;
// $enable-shadows:            false !default;
// $enable-gradients:          false !default;
// $enable-transitions:        true !default;
$kite-enable-hover-media-query: false !default;
// $enable-grid-classes:       true !default;
// $enable-print-styles:       true !default;

// Spacing
// ---------------------------

$kite-spacer: 1rem !default;
$kite-spacer-lg: 1.5rem !default;
// $spacer-x: $spacer !default;
$kite-spacer-y: $kite-spacer-lg !default;
$kite-spacer-section: $kite-spacer-lg * 2 !default;

// Body
// ---------------------------

// Settings for the `<body>` element.

$kite-body-bg: $kite-gray-1 !default;
$kite-body-color: $kite-black !default;
$kite-inverse-bg: $kite-dark-blue-3 !default;
$kite-inverse-color: $kite-white !default;

// Components
// ---------------------------

// Define common padding and border radius sizes and more.

$kite-border-width: 1px !default;

$kite-border-radius: 0.25rem !default;
// $border-radius-lg:       .3rem !default;
$kite-border-radius-sm: 0.125rem !default;

$kite-focus-border-width: 2px !default;
$kite-focus-border-color: $kite-blue-3 !default;

// Z-index master list
// ---------------------------

// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// $zindex-dropdown:           1000 !default;
// $zindex-sticky:             1020 !default;
// $zindex-fixed:              1030 !default;
// $zindex-modal-backdrop:     1040 !default;
// $zindex-modal:              1050 !default;
// $zindex-popover:            1060 !default;
// $zindex-tooltip:            1070 !default;
