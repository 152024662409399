@use '../../styles' as *;

.footer {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: #f8f8f8;
    border-top: solid 1px #d8dde6;
    padding-top: 50px;
  
    &__links {
      list-style: none;
      text-align: center;
      padding: 0;
    }
  
    &__link {
      display: inline-block;
      padding: 0 15px;
      margin: 5px 0;
      line-height: 16px;
      color: #006dbe;
  
      a {
        text-decoration: none;
  
        &:hover {
          text-decoration: underline;
        }
      }
  
      &--border {
        border-right: 1px solid #006dbe;
      }
    }
  
    @media only screen and (max-width: 600px) {
      br {
        display: none;
      }
  
      &__links {
        width: 90%;
        padding: 0;
      }
  
      &__link {
        display: block;
        margin-bottom: 10px;
        padding: 0;
  
        &--border {
          border-right: none;
        }
      }
    }
  }
  