@use '../../styles' as *;

.login {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  
    padding: 64px 0;
    
  
    &__title-container {
      max-width: 463px;
      margin-right: 106px;
    }
  
    &__card {
      width: 400px;
      padding: 32px 50px 64px 50px;
      background-color: #0a2742 !important;
      color: #fff;

      .kite-text-input {
        max-width: 100% !important;
      }
    }
  
    &__inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin: 0 auto;
    }
  
    &__title {
      font-size: 30px !important;
      margin-bottom: 16px !important;
      text-align: center;
    }
  
    &__language-button {
      margin-top: 50px;
      width: 100px;
      padding: 0 !important;
      width: fit-content !important;
  
      .kite-btn__content {
        text-align: left;
        display: inline-block;
      }
    }
  
    &__or-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      width: 100%;
      margin-bottom: 16px;
  
      p {
        margin: 0;
      }
    }
  
    &__divider {
      width: 45%;
      height: 2px;
      background-color: #0073d1;
    }
  
    &__create-username {
      margin-bottom: 48px;
    }
  
    &__page-alert {
      margin-bottom: 32px !important;
    }
  
    &__form {
      width: 100%;
    }

    &__submit-button {
      margin-bottom: 1.5rem !important;
    }
  
    .kite-btn {
      margin-bottom: 24px;
    }
  
    .kite-link {
      text-decoration: underline;
    }
  
    .kite-loader__svg {
      circle {
        stroke: rgb(0, 115, 209) !important;
      }
    }
  
    @media only screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
  
      &__language-button {
        margin: 15px auto 0;
      }
  
      &__title-container {
        margin: 0 0 32px 0;
        min-width: 300px;
        width: 400px;
        
        h1 {
          text-align: center;
          font-size: var(--kite-typography-sans-lg-title-3-size);
          margin-bottom: 2rem;
        }

        p {
          margin-bottom: 0.25rem;
        }
      }

     
    }
  
    @media only screen and (max-width: 450px) {
  
      &__title-container {
        width: 90%;    
        
        h1 {
          font-size: var(--kite-typography-sans-lg-title-3-size);
        }
      }
  
      &__card {
        width: 95%;
        padding: 16px;
      }
    }
  }
  