@use '../../styles' as *;

.layout {
  main {
    padding: 4rem 2rem;
    // @include grid(auto, auto, center);
    min-height: calc(100vh - 272px);
  }

  h1 {
    font-size: var(--kite-typography-sans-sm-title-3-size);
  }

  @media only screen and (max-width: '875px') {
    main {
      padding: 3rem 2rem;
    }
  }

  @media only screen and (max-width: '600px') {
    main {
      padding: 1.5rem 1rem;
    }
  }
}
