@import '../../grid/variables';

////
/// This is a poster comment.
/// It will apply annotations to all items from file.
/// @author Christopher Dura [@dura](https://gitlab.spectrumxg.com/dura)
////

// Breakpoint viewport sizes and media queries.
// Breakpoints are defined as a map of (name: minimum width), order from small to large:
// The map defined in the `$kite-grid-breakpoints` global variable is used as the `$breakpoints` argument by default.

/// Name of the next breakpoint, or `null` for the last breakpoint.
///
/// @param {String} $name - Supported values: `xs`, `sm`, `md`, `lg`, `xl`, `xxl`
/// @param {Map} $breakpoints [$kite-grid-breakpoints] - Map of available breakpoints
/// @param {List} $breakpoint-names [map-keys($breakpoints)] - Names of available breakpoints
/// @return {String | null} - Name of the next breakpoint
@function kite-breakpoint-next(
  $name,
  $breakpoints: $kite-grid-breakpoints,
  $breakpoint-names: map-keys($breakpoints)
) {
  $n: index($breakpoint-names, $name);
  @return if(
    $n < length($breakpoint-names),
    nth($breakpoint-names, $n + 1),
    null
  );
}

/// Name of the previous breakpoint, or `null` for the first breakpoint.
///
/// @param {String} $name - Supported values: `xs`, `sm`, `md`, `lg`, `xl`, `xxl`
/// @param {Map} $breakpoints [$kite-grid-breakpoints] - Map of available breakpoints
/// @param {List} $breakpoint-names [map-keys($breakpoints)] - Names of available breakpoints
/// @return {String | null} - Name of the previous breakpoint
@function kite-breakpoint-prev(
  $name,
  $breakpoints: $kite-grid-breakpoints,
  $breakpoint-names: map-keys($breakpoints)
) {
  $n: index($breakpoint-names, $name);
  @return if($n > 1, nth($breakpoint-names, $n - 1), null);
}

/// Minimum breakpoint width. `Null` for the smallest (first) breakpoint.
///
/// @param {String} $name - Supported values: `xs`, `sm`, `md`, `lg`, `xl`, `xxl`
/// @param {Map} $breakpoints [$kite-grid-breakpoints] - Map of available breakpoints
/// @return {Number} - The minimum width of the breakpoint
/// @example scss - Basic Usage Sass
///   .widget {
///     width: kite-breakpoint-min(sm);
///   }
///
/// @example css - Basic Usage CSS Output
///   .widget {
///     width: 600px;
///   }
@function kite-breakpoint-min($name, $breakpoints: $kite-grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

/// Maximum breakpoint width. `Null` for the largest (last) breakpoint.
///
/// @param {String} $name - Supported values: `xs`, `sm`, `md`, `lg`, `xl`, `xxl`
/// @param {Map} $breakpoints [$kite-grid-breakpoints] - Map of available breakpoints
/// @return {Number} - The maximum width of the breakpoint
@function kite-breakpoint-max($name, $breakpoints: $kite-grid-breakpoints) {
  $next: kite-breakpoint-next($name, $breakpoints);
  @return if($next, kite-breakpoint-min($next, $breakpoints) - 1px, null);
}

/// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash infront.
/// Useful for making responsive utilities
///
/// @param {String} $name - Supported values: `xs`, `sm`, `md`, `lg`, `xl`, `xxl`
/// @param {Map} $breakpoints [$kite-grid-breakpoints] - Map of available breakpoints
/// @return {String | null} - Name of the breakpoint with a dash infront.
@function kite-breakpoint-infix($name, $breakpoints: $kite-grid-breakpoints) {
  @return if(kite-breakpoint-min($name, $breakpoints) == null, '', '-#{$name}');
}

/// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
/// Makes the `@content` apply to the given breakpoint and wider.
///
/// @param {String} $name - Supported values: `xs`, `sm`, `md`, `lg`, `xl`
/// @param {Map} $breakpoints [$kite-grid-breakpoints] - Map of available breakpoints
///
/// @example scss - Basic Usage Sass
///     .widget {
///         color: red;
///
///         @include kite-media-breakpoint-up(lg) {
///             color: blue;
///         }
///     }
///
/// @example css - Basic Usage CSS Output
///     .widget {
///         color: red;
///     }
///
///     @media (min-width: 600px) {
///         .widget {
///             color: blue;
///         }
///     }
///
/// @content This mixin allows extra content to be passed (through the `@content` directive).
/// @throw `@warn` if `$name` is not a supported value.
@mixin kite-media-breakpoint-up($name, $breakpoints: $kite-grid-breakpoints) {
  @if map-has-key($breakpoints, $name) {
    $min: kite-breakpoint-min($name, $breakpoints);
    @if $min {
      @media (min-width: $min) {
        @content;
      }
    } @else {
      @content;
    }
  } @else {
    @content;
    @warn "`#{$name}` is not a supported breakpoint, so no media query was generated.";
  }
}

/// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
/// Makes the `@content` apply to the given breakpoint and narrower.
///
/// @param {String} $name - Supported values: `xs`, `sm`, `md`, `lg`, `xl`
/// @param {Map} $breakpoints [$kite-grid-breakpoints] - Map of available breakpoints
///
/// @example scss - Basic Usage Sass
///     .widget {
///         color: red;
///
///         @include kite-media-breakpoint-down(lg) {
///             color: blue;
///         }
///     }
///
/// @example css - Basic Usage CSS Output
///     .widget {
///         color: red;
///     }
///
///     @media (max-width: 959px) {
///         .widget {
///             color: blue;
///         }
///     }
///
/// @content This mixin allows extra content to be passed (through the `@content` directive).
/// @throw `@warn` if `$name` is not a supported value.
@mixin kite-media-breakpoint-down($name, $breakpoints: $kite-grid-breakpoints) {
  @if map-has-key($breakpoints, $name) {
    $max: kite-breakpoint-max($name, $breakpoints);
    @if $max {
      @media (max-width: $max) {
        @content;
      }
    } @else {
      @content;
    }
  } @else {
    @content;
    @warn "`#{$name}` is not a supported breakpoint, so no media query was generated.";
  }
}

/// Media that spans multiple breakpoint widths.
/// Makes the `@content` apply between the min and max breakpoints
@mixin kite-media-breakpoint-between(
  $lower,
  $upper,
  $breakpoints: $kite-grid-breakpoints
) {
  $min: kite-breakpoint-min($lower, $breakpoints);
  $max: kite-breakpoint-max($upper, $breakpoints);

  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}

/// Media between the breakpoint's minimum and maximum widths.
/// No minimum for the smallest breakpoint, and no maximum for the largest one.
/// Makes the `@content` apply only to the given breakpoint, not viewports any wider or narrower.
///
/// @param {String} $name - Supported values: `xs`, `sm`, `md`, `lg`, `xl`
/// @param {Map} $breakpoints [$kite-grid-breakpoints] - Map of available breakpoints
///
/// @example scss - Basic Usage Sass
///     .widget {
///         color: red;
///
///         @include media-breakpoint-only(lg) {
///             color: blue;
///         }
///     }
///
/// @example css - Basic Usage CSS Output
///     .widget {
///         color: red;
///     }
///
///     @media (min-width: 840px) and (max-width: 959px) {
///         .widget {
///             color: blue;
///         }
///     }
///
/// @content This mixin allows extra content to be passed (through the `@content` directive).
/// @throw `@warn` if `$name` is not a supported value.
@mixin kite-media-breakpoint-only($name, $breakpoints: $kite-grid-breakpoints) {
  @if map-has-key($breakpoints, $name) {
    $min: kite-breakpoint-min($name, $breakpoints);
    $max: kite-breakpoint-max($name, $breakpoints);

    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else {
    @content;
    @warn "`#{$name}` is not a supported breakpoint, so no media query was generated.";
  }
}
