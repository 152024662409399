@use '../../styles' as *;

.header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    height: 72px !important;
    width: 100%;
    background-color: #f8f8f8;
    border-bottom: solid 1px #d8dde6;

    &__language-button {
        position: absolute !important;
        top: 50%;
        transform: translateY(-50%);
        right: 32px;
        max-width: 100px;
    }

    @media only screen and (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
        justify-content: flex-start;

        &__spectrum-logo {
            margin-left: 32px;
        }
    }
} 

