@use '../../styles' as *;

.notfound {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin-bottom: 40px !important;
  }

  img {
    height: 90%;
    width: 90%;
    max-width: 500px;
    max-height: 333px;
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    background-color: #f8f8f8 !important;
  }

  &__title {
    font-size: 40px !important;
    margin-bottom: 16px !important;
  }

  @media only screen and (max-width: 799px) {
    &__card {
      width: 90%;
    }
  }

  @media only screen and (min-width: 800px) and (max-width: 1200px) and (orientation: portrait) {
    &__card {
      width: 75%;
    }
  }

  @media only screen and (min-width: 800px) and (max-width: 1200px) and (orientation: landscape) {
    &__card {
      width: 85%;
    }
  }
}
