@use '../../styles' as *;

.spectrumone {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  video {
    width: 60%;
  }

  @media only screen and (max-width: 799px) {
    video {
      width: 90%;
    }
  }

  @media only screen and (min-width: 800px) and (max-width: 1200px) and (orientation: portrait) {
    video {
      width: 75%;
    }
  }

  @media only screen and (min-width: 800px) and (max-width: 1200px) and (orientation: landscape) {
    video {
      width: 85%;
    }
  }
}
