@import './globals/shared/mixins';
@import './globals/theme/mixins';
@import './globals/shared/variables';

// Table Variables
// ---------------------------

// Customizes the `.table` component with basic values, each used across all table variations.

$kite-table-cell-padding: 0.75rem !default;
// $table-sm-cell-padding:         .3rem !default;

$kite-table-bg: transparent !default;



$kite-table-bg-accent: $kite-gray-light !default;
// $table-bg-hover:                $table-bg-accent; // rgba($black,.075) !default;
// $table-bg-active:               $table-bg-hover !default;

// $table-head-bg:                 $gray-lighter !default;
// $table-head-color:              $gray !default;

$kite-table-border-width: $kite-border-width !default;
$kite-table-border-color: $kite-gray-2 !default;

// Tables
// ---------------------------------------------------------

.kite-table {
  background-color: $kite-table-bg;
  font-size: 0.875rem;
  margin-bottom: $kite-spacer-lg;
  max-width: 100%;
  width: 100%;

  @include kite-media-breakpoint-up(lg) {
    font-size: 1rem;
  }

  &__wrapper {
    overflow-x: scroll;
  }

  thead {
    th {
      vertical-align: bottom;
      border-bottom: $kite-table-border-width solid $kite-table-border-color;
    }
  }

  th,
  td {
    padding: $kite-table-cell-padding;
    vertical-align: top;

    &:last-of-type {
      text-align: right;
    }

    @include kite-media-breakpoint-up(lg) {
      &:last-of-type {
        text-align: inherit;
      }
    }
  }

  
}

// Fixed width columns
// ---------------------------

.kite-table-fixed {
  table-layout: fixed;

  .kite-table-cell-25 {
    width: 25%;
  }

  .kite-table-cell-15 {
    width: 15%;
  }
}

// Zebra-striping
// ---------------------------

.kite-table-striped {
  tbody tr:nth-of-type(even) {
    background-color: $kite-table-bg-accent;
  }
}

// Hover effect
// ---------------------------

// Placed here since it has to come after the potential zebra striping
// Also, `border-collapse: collapse;` causes the `tr` and `td` borders
// to overlap, so we need to do some magic to display them on hover correctly.
// Or, change to `border-collapse: separate` and change `border-spacing`.

.kite-table-hover {
  tbody {
    tr {
      border-top: $kite-table-border-width solid transparent;
      border-bottom: $kite-table-border-width solid transparent;

      @include kite-hover {
        td {
          background-color: $kite-table-bg-accent;
          border-top: $kite-table-border-width solid $kite-gray;
          border-bottom: $kite-table-border-width solid $kite-gray;
          transition: background-color 85ms ease-in-out;
        }
      }
    }
  }
}

// Responsive tables
// ---------------------------

.kite-table-responsive {
  display: block;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057

  @include kite-media-breakpoint-up(lg) {
    display: table; // reset so that responsive tables with small data-sets go 100% wide
  }
}

// Theme
// ---------------------------

@include kite-theme('.kite-table') {
  &.kite-table-hover {
    tbody {
      tr {
        @include kite-hover {
          td {
            background-color: $kite-dark-blue-1;
            border-top: $kite-table-border-width solid $kite-dark-blue-1;
            border-bottom: $kite-table-border-width solid $kite-dark-blue-1;
          }
        }
      }
    }
  }
}
